html,
body {
  height: 100%;
}

.logo-bar {
  top: 0;
  position: fixed;
  display: flex;
  padding: 30px;
  align-items: center;
  font-size: 30px;
  color: black;
}

.logo-bar:hover {
  text-decoration: none;
  font-weight: none;
}

body.dark .logo-bar {
  color: white;
}

.logo-bar img {
  margin-right: 10px;
}

#powered-by-label {
  color: rgb(202, 202, 202);
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#powered-by-label a {
  color: grey;
}

.login-dialog {
  width: 480px;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 60px 26px;
  background-color: var(--surface-color);
  border-radius: 6px;
  color: var(--text-color);
  box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
  position: relative;
  bottom: -50px;
  min-height: 300px;
 text-align: center;
}

.login-dialog-title {
  font-size: 30px;
  margin-bottom: 20px;
}

body.dark .login-dialog {
  color: var(--dark-text-color);
  background-color: var(--dark-surface-color);
}

.error-message{
  color: var(--error-color);
}

.login-dialog-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: grey;
  margin-top: 50px;
}
.login-dialog-footer .link{
  margin-left: 10px;
  color: var(--highlight-color);
  cursor: pointer;
}
.login-dialog-footer .link:hover{
  margin-left: 10px;
  color: var(--highlight-color);
  font-weight: 700;
}
