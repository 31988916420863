.profile-button{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  border-radius: 5px;
  cursor:pointer;
  padding: 5px 5px;
}


.profile-button:hover{
  background-color: rgb(226, 226, 226);
}
body.dark .profile-button:hover{
  background-color: rgb(55, 55, 55);
}


.profile-button__info__email{
font-size: 10px;
width: 123px;
overflow: hidden;
}
.profile-button__info__name{
font-size: 15px;
width: 123px;
overflow: hidden;
}