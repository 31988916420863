.login-form-field {
  background: var(--overlay-color);
  border: 1px solid var(--background-color);
  color: var(--text-color);
  margin: 10px 0px 20px 0px;
  height: 42px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
}

body.dark .login-form-field {
  background: var(--dark-overlay-color);
  border: 1px solid var(--dark-background-color);
  caret-color: var(--highlight-color);
  color: var(--dark-text-color);
}

body.dark .login-form-field::placeholder {
  color: var(--dark-text-color);
}

.login-form-field.invalid {
  border: 2px solid var(--error-color);
}

body.dark .login-form-field.invalid {
  border: 2px solid var(--error-color);
}

.invalid-message {
  font-size: small;
  color: var(--error-color-contrast);
}
