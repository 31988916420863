.service-unavailable-page {
  background: url(../../assets/images/background/login-bg.svg) bottom/cover no-repeat;
  padding: 80px 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
}

.page-dialog {
  width: 480px;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 60px 26px;
  background-color: var(--surface-color);
  border-radius: 6px;
  color: var(--text-color);
  box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
  position: relative;
  bottom: -50px;
  text-align: center;
}

body.dark .page-dialog {
  color: var(--dark-text-color);
  background-color: var(--dark-surface-color);
}

#title {
  font-size: 30px;
  margin-bottom: 20px;
}

#message {
  font-size: 18px;
  color: grey;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .page-dialog {
    padding: 35px;
  }
}
