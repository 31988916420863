html{
  height: 100%;
}

body {
  background-color: var(--background-color);
  color: grey;
  font-family: 'Open Sans';
  height: 100%;
}

body.dark {
  background-color: var(--dark-background-color);
  color: white;
}

.smartnest-page {
  background: url(../assets/images/background/login-bg.svg) bottom/cover no-repeat;
  padding: 80px 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
}

.smartnest-button {
  background-color: var(--highlight-color);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px 2px rgba(70, 70, 70, 0.212);
  margin: auto;
  width: 100%;
}

.smartnest-button:hover {
  box-shadow: 0px 3px 5px 2px rgba(66, 66, 66, 0.411);
}

body.dark .smartnest-button:hover {
  box-shadow: 0px 3px 5px 2px rgba(66, 66, 66, 0.856);
}

.cursor-pointer {
  cursor: pointer;
}

.page-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff00;
  overflow: hidden;
  z-index: -1;
}

.page-wrapper {
  min-height: 600px;
  max-width: 1030px;
  margin: 80px auto;
  padding: 0px 10px;
  justify-content: center;
}

.page-section {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  max-width: 700px;
  display: block;
  background-color: white;
  margin: 30px auto;
  padding-bottom: 30px;
  text-align: center;
}

body.dark .page-section {
  background-color: grey;
}

.page-head {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  background-color: white;
  position: relative;
}

body.dark .page-head {
  background-color: rgb(70, 70, 70);
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(8, 8, 8, 0.12);
}
