:root {
  /* light mode */
  --background-color: rgba(255, 255, 255, 1);
  --surface-color: rgba(250, 250, 255, 1);
  --text-color: rgba(33, 33, 33, 1);
  --overlay-color: rgb(214, 214, 214);

  /* dark mode */
  --dark-background-color: rgba(30, 30, 30, 1);
  --dark-surface-color: rgb(73, 73, 73);
  --dark-text-color: rgba(255, 255, 255, 1);
  --dark-overlay-color: rgb(42, 42, 42);

  /* All modes */
  --error-color: #d32f2f;
  --error-color-contrast: #ffafaf;
  --highlight-color: rgba(238, 128, 3, 1);
}
