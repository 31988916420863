
.login-form {
  width: 480px;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 60px 26px;
  background-color: var(--surface-color);
  border-radius: 6px;
  color: var(--text-color);
  box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
  position: relative;
  bottom: -50px;
}

body.dark .login-form {
  color: var(--dark-text-color);
  background-color: var(--dark-surface-color);
}


.forgot-password-text {
  cursor: pointer;
  margin: 0px 0 50px 0;
  color: lightgray;
}

.forgot-password-text:hover {
  color: grey;
}

.recaptcha {
  width: 100%;
  max-width: 304px;
  overflow: hidden;
  margin: 20px auto;
}

body.dark .recaptcha {
  filter: invert(0.9);
}

.login-form {
  text-align: center;
}

@media (max-width: 480px) {
  .login-form {
    padding: 35px;
  }
}
